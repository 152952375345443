import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import { getData, postData } from "../../FetchService";
import * as moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
const ManagePublicAddEvent = ({ imageSeter, image, convertImageToUrl }) => {
  const seter = imageSeter;
  const navigate = useNavigate();
  const location = useLocation();
  let edit = location.state.isEdit;
  const [showImg, setShowImg] = useState("");
  const [eventData, setEventData] = useState([]);
  const [search, setSearch] = useState("");
  const [Searchvalue, setSearchValue] = useState("");
  const [allevents, setAllEvents] = useState();
  const [loader, setLoader] = useState(false);
  const [defaultV, setDefaultV] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const error = "Daily is exceding";
  const [userEdit, setUserEdit] = useState();
  const [eventdataId, setEventDataId] = useState("");
  const [eventTemplate, setEventTemplate] = useState([]);
  const [filterTemplate, setfilterTemplate] = useState([]);
  const [filterTemplateAll, setfilterTemplateAll] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [formvalues, setFormValues] = useState({
    name: "",
    template: "",
    eventtype: "free",
    amount: "",
    startDate: "",
    startTime: "",
    endTime: "",
    lastJoinTime: "",
    endDate: "",
    lastJoinDate: "",
    description: "",
    points: "",
    rewardDesc: "",
    isCorporate: false,
    caloriesDaily: 0,
    caloriesLimit: 0,
    timeDaily: 0,
    timeLimit: 0,
    distanceDaily: 0,
    distanceLimit: 0,
    jumpsDaily: 0,
    jumpsLimit: 0,
    skipsDaily: 0,
    skipsLimit: 0,
    stepsDaily: 0,
    stepsLimit: 0,
  });
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const handleFormValues = (e) => {
    const { name, value, type, id } = e.target;
    console.log({ name, value, type, id }, "this is event typeeeeeeeeeeeee");
    if (type == "checkbox") {
      if (e.target.checked) {
        setFormValues({ ...formvalues, [name]: value }) &&
          setFormValues({ ...formvalues, [id]: value });
      } else {
        setFormValues({ ...formvalues, [name]: "" }) &&
          setFormValues({ ...formvalues, [id]: "" });
      }
    } else {
      setFormValues({ ...formvalues, [name]: value });
    }
  };

  const handleEventTemplate = async () => {
    const res = await getData("activity-templates");

    setEventTemplate(res.data);
  };

  useEffect(() => {
    handleEventTemplate();
    return () => {
      seter("");
    };
  }, []);

  const handleTemplateChange = (e) => {
    setfilterTemplateAll([]);
    if (filterTemplate.includes(e)) {
      let a = filterTemplate.filter((el) => {
        return el !== e;
      });
      setfilterTemplate(a);
    } else {
      setfilterTemplate([...filterTemplate, e]);
    }
  };

  const allFilterTemp = (ref) => {
    let data = ref.target.value;
    if (filterTemplate.join("").includes("12345")) {
      setfilterTemplate([]);
    } else {
      setfilterTemplate(data.split(","));
    }
  };

  const handleuserEvent = (e) => {
    e.preventDefault();
    setSearch(e.target.value);

    setSearchValue(e.target.value);
  };

  const handleSelectChange = (e) => {
    setEventDataId(e.target.value);
  };

  const searchData = async () => {
    let res = await getData(`search?search=${search}&offset=0&limit=10`);
    setEventData(res.data);
  };

  useEffect(() => {
    searchData();
  }, [search]);

  const handleEventId = (data) => {
    let n = `${data.firstName} ${data.lastName}`;
    setSearchValue(n);
    setSearch(n);
    setDefaultV(data._id);
  };

  useEffect(() => {
    if (edit == true) {
      setUserEdit(location.state.data);
      let s = location.state.data.time ? location.state.data.time : 0;
      setSearch(
        location.state.data.admin
          ? `${location.state.data.admin?.firstName} ${location.state.data.admin?.lastName}`
          : ""
      );
      setEventDataId(location?.state?.data?.admin?._id);
      setFormValues({
        name: location.state.data.name,
        description: location.state.data.description,
        template: location.state.data.template,
        startDate: moment(location.state.data.startDate).format("YYYY-MM-DD"),
        startTime: moment(location.state.data.startDate).format("HH:mm"),
        endDate: moment(location.state.data.endDate).format("YYYY-MM-DD"),
        endTime: moment(location.state.data.endDate).format("HH:mm"),
        rewardDesc: location.state.data.rewardDesc,
        activities:
          filterTemplateAll.length == 5 ? filterTemplateAll : filterTemplate,
        // admin: location.state.data._id,
        isCorporate: location.state.data.isCorporate,
        points: location.state.data.points,
        maximum: location.state.data.maximum,
        amount: location.state.data.amount,
        caloriesDaily: location.state.data.calories.daily,
        caloriesLimit: location.state.data.calories.limit,
        timeDaily: location.state.data.time.daily,
        timeLimit: location.state.data.time.limit,
        distanceDaily: location.state.data.distance.daily,
        distanceLimit: location.state.data.distance.limit,
        jumpsDaily: location.state.data.jumps.daily,
        jumpsLimit: location.state.data.jumps.limit,
        skipsDaily: location.state.data.skips.daily,
        skipsLimit: location.state.data.skips.limit,
        stepsDaily: location.state.data.steps.daily,
        stepsLimit: location.state.data.steps.limit,
        lastJoinDate: moment(location.state.data.lastJoinDate).format(
          "YYYY-MM-DD"
        ),
        lastJoinTime: moment(location.state.data.lastJoinDate).format("hh:mm"),
      });

      seter(location?.state?.data?.image);
      let ar = [];
      location.state.data.activities.map((el) => {
        ar.push(`${el}`);
      });
      setfilterTemplate(ar);
      let n = `${location.state?.data?.admin?.firstName} ${location?.state?.data?.admin?.lastName}`;
      setSearchValue(n);
      setSearch(n);
    }
  }, [edit]);

  const handleAllEventdata = async (e) => {
    e.preventDefault();
    setShowImg("");
    setFormErrors(validate(formvalues));

    let data = {
      name: formvalues.name,
      image: image,
      description: formvalues.description,
      template: formvalues.template,
      activities:
        filterTemplateAll.length == 5
          ? filterTemplateAll.map((el) => String(el))
          : filterTemplate.map((el) => String(el)),
      startDate: new Date(`${formvalues.startDate} ${formvalues.startTime}`),
      // startDate: moment(formvalues.startDate).format("YYYY-MM-DD"),
      endDate: new Date(`${formvalues.endDate} ${formvalues.endTime}`),
      // endDate: moment(formvalues.endDate).format("YYYY-MM-DD"),
      rewardDesc: formvalues.rewardDesc,
      // admin: eventdataId,
      isCorporate: formvalues.isCorporate,
      isMedal: isChecked,
      points: formvalues.points,
      amount: Number(formvalues.amount),
      calories: {
        daily: formvalues.caloriesDaily,
        limit: formvalues.caloriesLimit,
      },
      time: {
        limit: Math.floor(formvalues.timeLimit),
        daily: Math.floor(formvalues.timeDaily),
      },
      distance: {
        limit: formvalues.distanceLimit,
        daily: formvalues.distanceDaily,
      },
      jumps: {
        limit: formvalues.jumpsLimit,
        daily: formvalues.jumpsDaily,
      },
      skips: {
        limit: formvalues.skipsLimit,
        daily: formvalues.skipsDaily,
      },
      steps: {
        limit: formvalues.stepsLimit,
        daily: formvalues.stepsDaily,
      },
      lastJoinDate: new Date(
        `${formvalues.lastJoinDate} ${formvalues.lastJoinTime}`
      ),
    };

    if (formvalues.maximum) {
      data.maximum = Number(formvalues.maximum);
    }

    if (edit == true) {
      const res = await postData(
        `edit-event/${location?.state?.data?._id}`,
        data
      );
      if (res.status == true) {
        setTimeout(() => {
          navigate("/managepublicevent");
          seter("");
        }, 2000);
        toast.success(" Updated successfully  !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      const res = await postData("create-event", data);
      if (res.status == true) {
        seter("");
        setTimeout(() => {
          navigate("/managepublicevent");
        }, 2000);
        toast.success(`${res.message} !`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  console.log(
    { type: formvalues.eventtype, amount: formvalues.amount },
    "this is formValue"
  );

  const handleLoader = () => {
    if (showImg !== "" && image == "") {
      setLoader(true);
    } else {
      setLoader(false);
    }
  };

  const validate = (values) => {
    const startDate = new Date(values.startDate).getTime();
    const endDate = new Date(values.endDate).getTime();
    const lastJoinDate = new Date(values.lastJoinDate).getTime();
    const afterAWeeek = new Date(values.startDate).getTime() + 86400000 * 7;
    const errors = {};

    if (!filterTemplate.length) {
      errors.filterTemplate = "Minimum 1 activity is required !";
    }
    // if (!eventdataId) {
    //   errors.admin = "Event admin is required !";
    // }
    if (!image) {
      errors.image = "Event image is required !";
    }
    if (!values.name) {
      errors.name = "Event title is required !";
    }
    if (!values.points) {
      errors.points = "Points is required !";
    }
    if (!values.rewardDesc) {
      errors.rewardDesc = "Reward description is required !";
    }
    if (!values.template) {
      errors.template = "Event short description is required !";
    }
    if (!values.description) {
      errors.description = "Event description is required !";
    }
    if (!values.startDate) {
      errors.startDate = "Start date is required !";
    }

    // else if (startDate < new Date().getTime()) {
    //   errors.startDate = "Start date must be after a day from today !";
    // }
    if (!values.endDate) {
      errors.endDate = "End date is required !";
    } else if (endDate > afterAWeeek) {
      errors.endDate = "End Date cannot be more than 7 days from start date";
    }
    if (!values.lastJoinDate) {
      errors.lastJoinDate = "Last join date is required !";
    } else if (lastJoinDate < startDate || lastJoinDate > endDate) {
      errors.lastJoinDate =
        "Last join date must be between start date & end date";
    }
    return errors;
  };

  console.log({ image, showImg });

  useEffect(() => {
    handleLoader();
  }, [showImg, image]);

  useEffect(() => {
    if (formvalues.eventtype === "free") {
      setFormValues((prv) => {
        return { ...prv, amount: 0 };
      });
    }
  }, [formvalues.eventtype]);

  console.log({ formvalues });

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div className="main mt-8">
        <div className="content container-fluid addEditHealthArticles manage_user manage_event_list">
          <div className="pageheader">
            <div className="row g-2 align-items-center mb-3">
              <div className="col">
                <h1 className="page-header-title m-0">
                  {edit ? "Edit" : "Add"} Public Event Details
                </h1>
              </div>
              <div className="col-sm-auto mt-sm-0 mt-2 text-end">
                <button
                  className="btn btn-primary addEventBtn"
                  onClick={handleAllEventdata}
                >
                  {edit ? "UPDATE" : "ADD"}
                </button>
              </div>
              <div className="col-sm-auto mt-sm-0 mt-2 text-end">
                <button
                  type="button"
                  className="btn btn-danger addEventBtn"
                  onClick={() => {
                    setShowImg("");
                    seter("");
                    if (edit) {
                      if (location?.state?.data?.amount > 0) {
                        navigate("/managepublicevent/vieweventdetailspaid", {
                          state: {
                            items: location?.state?.data?._id,
                            user: location?.state?.data,
                          },
                        });
                      } else {
                        navigate("/managepublicevent/vieweventdetailsfree", {
                          state: {
                            items: location?.state?.data?._id,
                            user: location?.state?.data,
                          },
                        });
                      }
                    } else {
                      navigate("/managepublicevent");
                    }
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div className="row g-3">
            <div className="col-12">
              <div className="row g-2 mb-4">
                <div className="col-12 col-sm-6 col-md-5 col-lg-4">
                  <div className="col-12 px0 fs-3 fw-bold text-black mb-3">
                    Basic Event Details
                  </div>
                  <div className="card">
                    <div className="card-body p-0">
                      <label
                        htmlFor="attachFilesNewProjectLabel"
                        className="js-dropzone dz-dropzone dz-dropzone-card"
                      >
                        <div className="dz-message">
                          <>
                            {loader ? (
                              <div>
                                <ColorRing
                                  visible={true}
                                  height="80"
                                  width="80"
                                  ariaLabel="blocks-loading"
                                  //  wrapperStyle={{ marginTop: "100px" }}
                                  wrapperclassName="blocks-wrapper"
                                  colors={[
                                    "#b8c480",
                                    "#B2A3B5",
                                    "#F4442E",
                                    "#51E5FF",
                                    "#429EA6",
                                  ]}
                                />
                              </div>
                            ) : (
                              <img
                                className="avatar avatar-xl avatar-4x3 mb-3"
                                src={showImg ? showImg : image}
                                data-hs-theme-appearance="default"
                                alt="Image Description"
                              />
                            )}
                            {/* <img
                              className="avatar avatar-xl avatar-4x3 mb-3 d-none"
                              src={image}
                              alt="Image Description"
                              data-hs-theme-appearance="dark"
                            /> */}
                          </>

                          <h5>Drag and drop your file here</h5>
                          <p className="mb-2">or</p>
                          <span className="btn btn-white btn-sm">
                            Browse files
                          </span>
                        </div>
                      </label>
                      <input
                        type="file"
                        className="d-none"
                        name=""
                        id="attachFilesNewProjectLabel"
                        onChange={(e) => {
                          convertImageToUrl(e);
                          setShowImg(URL.createObjectURL(e.target.files[0]));
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="text-dark mt-2">Upload Article Image</div>
                </div>
                {formErrors?.image ? (
                  <div className="text-danger pt-1">{formErrors?.image}</div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-12">
              <div className="row pb-5 mb-5">
                <div className="col-12 mb-3">
                  <div className="col-md-6 col-sm-8 px-0">
                    <label htmlFor="name" className="form-label">
                      Event Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      placeholder="Enter Event Title"
                      aria-label=""
                      value={formvalues.name}
                      onChange={handleFormValues}
                    />
                  </div>{" "}
                  {formErrors?.name ? (
                    <div className="text-danger pt-1">{formErrors?.name}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-12 mb-3">
                  <div className="col-md-6 col-sm-8 px-0">
                    <label htmlFor="template" className="form-label">
                      Event Short Description
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="template"
                      id="template"
                      placeholder="Enter Event Short Description"
                      aria-label=""
                      value={formvalues.template}
                      onChange={handleFormValues}
                    />
                  </div>
                  {formErrors?.template ? (
                    <div className="text-danger pt-1">
                      {formErrors?.template}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-12 row px2 mx-0 mb-3">
                  <div className="col-12 px-0 mb-3">
                    <label htmlFor="" className="form-check-label">
                      Event Type
                    </label>
                  </div>
                  <div className="col-12 px-0 d-flex gap-3">
                    <div className="form-check p-0 align-items-center d-flex">
                      <input
                        className="form-check-input rounded-1 m-0"
                        type="radio"
                        name="eventtype"
                        id="free"
                        value="free"
                        onChange={handleFormValues}
                        checked={
                          (edit &&
                            formvalues.amount === 0 &&
                            !formvalues.eventtype) ||
                          formvalues.eventtype === "free"
                            ? true
                            : false
                        }
                      />
                      <label className="form-check-label ps-2" htmlFor="free">
                        FREE
                      </label>
                    </div>
                    <div className="form-check p-0 align-items-center d-flex">
                      <input
                        className="form-check-input rounded-1 m-0"
                        type="radio"
                        name="eventtype"
                        id="paid"
                        value="paid"
                        onChange={handleFormValues}
                        checked={
                          (edit && formvalues.amount > 0) ||
                          (edit && formvalues.eventtype === "paid") ||
                          formvalues.eventtype === "paid"
                            ? true
                            : false
                        }
                      />
                      <label className="form-check-label ps-2" htmlFor="paid">
                        PAID
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="col-md-6 col-sm-8 px-0">
                    <input
                      type="number"
                      className="form-control"
                      name="amount"
                      disabled={
                        formvalues.eventtype === "free" ||
                        (edit &&
                          formvalues.amount === 0 &&
                          !formvalues.eventtype) ||
                        formvalues.eventtype === "free"
                          ? true
                          : false
                      }
                      id="amount"
                      placeholder="Enter Event Amount"
                      aria-label=""
                      value={formvalues.amount}
                      onChange={handleFormValues}
                    />
                  </div>
                </div>

                <div>
                  <div className="col-12 mb-3">
                    <div className="col-md-6 col-sm-8 px-0">
                      <label htmlFor="startDate" className="form-label">
                        Event Start Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        name="startDate"
                        id="startDate"
                        placeholder="Enter Event Amount"
                        aria-label=""
                        value={formvalues?.startDate}
                        onChange={handleFormValues}
                      />
                    </div>
                    {formErrors?.startDate ? (
                      <div className="text-danger pt-1">
                        {formErrors?.startDate}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 mb-3">
                    <div className="col-md-6 col-sm-8 px-0">
                      <label htmlFor="startTime" className="form-label">
                        Event Start Time
                      </label>
                      <input
                        type="time"
                        className="form-control"
                        name="startTime"
                        id="startTime"
                        // placeholder="Enter Event Amount"
                        aria-label=""
                        value={formvalues?.startTime}
                        onChange={handleFormValues}
                      />
                    </div>
                    {formErrors?.startTime ? (
                      <div className="text-danger pt-1">
                        {formErrors?.startTime}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 mb-3">
                    <div className="col-md-6 col-sm-8 px-0">
                      <label htmlFor="endDate" className="form-label">
                        Event End Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        name="endDate"
                        id="eventitle3"
                        placeholder="Enter Event Amount"
                        aria-label=""
                        value={formvalues.endDate}
                        onChange={handleFormValues}
                      />
                    </div>
                    {formErrors?.endDate ? (
                      <div className="text-danger pt-1">
                        {formErrors?.endDate}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 mb-3">
                    <div className="col-md-6 col-sm-8 px-0">
                      <label htmlFor="endTime" className="form-label">
                        Event End Time
                      </label>
                      <input
                        type="time"
                        className="form-control"
                        name="endTime"
                        id="eventitle3"
                        // placeholder="Enter Event Amount"
                        aria-label=""
                        value={formvalues.endTime}
                        onChange={handleFormValues}
                      />
                    </div>
                    {formErrors?.endTime ? (
                      <div className="text-danger pt-1">
                        {formErrors?.endTime}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 mb-3">
                    <div className="col-md-6 col-sm-8 px-0">
                      <label htmlFor="lastJoinDate" className="form-label">
                        Last Join Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        name="lastJoinDate"
                        id="lastJoinDate"
                        placeholder="Enter Event Amount"
                        aria-label=""
                        value={formvalues.lastJoinDate}
                        onChange={handleFormValues}
                      />
                    </div>
                  </div>

                  {formErrors?.lastJoinDate ? (
                    <div className="text-danger pt-1">
                      {formErrors?.lastJoinDate}
                    </div>
                  ) : null}

                  <div className="col-12 mb-3">
                    <div className="col-md-6 col-sm-8 px-0">
                      <label htmlFor="lastJoinTime" className="form-label">
                        Last Join Time
                      </label>
                      <input
                        type="time"
                        className="form-control"
                        name="lastJoinTime"
                        id="lastJoinTime"
                        // placeholder="Enter Event Amount"
                        aria-label=""
                        value={formvalues.lastJoinTime}
                        onChange={handleFormValues}
                      />
                    </div>
                  </div>

                  {formErrors?.lastJoinTime ? (
                    <div className="text-danger pt-1">
                      {formErrors?.lastJoinTime}
                    </div>
                  ) : null}
                </div>

                <div className="col-12">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <textarea
                    className="form-control"
                    name="description"
                    placeholder=""
                    id="description"
                    rows="10"
                    value={formvalues.description}
                    onChange={handleFormValues}
                  ></textarea>
                </div>
                {formErrors?.description ? (
                  <div className="text-danger pt-1">
                    {formErrors?.description}
                  </div>
                ) : null}
              </div>

              <div className="row mx-0 pb-5 mb-5">
                <div className="col-12 px-0 fs-3 fw-bold text-black mb-3">
                  Qualifying Activities
                </div>
                <div className="col-12 px-0 fs-5 fw-bold text-black">
                  Activity Type (Multiple Selection)
                </div>
                <div className="col-12 chech_Box_Custom px-0">
                  <small
                    className={
                      filterTemplate.length ? "d-none" : "d-block text-danger"
                    }
                  >
                    Please Select any of category*
                  </small>
                  <ul
                    className="nav nav-tabs border-bottom-0 d-flex align-items-center scroll_card"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link border-bottom-0 px-0 d-flex align-items-center active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        <span>
                          <div className="form-check">
                            <input
                              className="form-check-input rounded-1 p-2"
                              type="checkbox"
                              value={"1,2,3,4,5"}
                              id="flexCheckDefault"
                              name="flexRadioDefault"
                              onChange={(e) => allFilterTemp(e)}
                              checked={filterTemplate
                                .sort()
                                .join("")
                                .includes("12345")}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              All
                            </label>
                          </div>
                        </span>
                      </button>
                    </li>
                    {eventTemplate.map((el, i) => {
                      return (
                        <div key={i}>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link border-bottom-0 px-3 d-flex align-items-center"
                              id="profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#home"
                              type="button"
                              role="tab"
                              aria-controls="profile"
                              aria-selected="false"
                              tabIndex="-1"
                            >
                              <span>
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input rounded-1 p-2"
                                    value={el.ref}
                                    onChange={(e) =>
                                      handleTemplateChange(e.target.value)
                                    }
                                    checked={filterTemplate.includes(
                                      `${el.ref}`
                                    )}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`${el._id}flexCheckCheckedRunning`}
                                  >
                                    {el.activity}
                                  </label>
                                </div>
                              </span>
                            </button>
                          </li>
                        </div>
                      );
                    })}
                  </ul>
                  {formErrors?.filterTemplate ? (
                    <div className="text-danger pt-1 pb-1">
                      {formErrors?.filterTemplate}
                    </div>
                  ) : null}
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade active show"
                      //id="profile"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="row mx-0 ">
                        <div className="col-lg-2 col-md-3 col-sm-5 col-12 form-check px-0 pe-sm-2">
                          <div className="fw-bold fs-4 text-black mb-3 ">
                            Calories
                          </div>
                          <div className="mb-3">
                            <span> Limit: </span>
                            <input
                              style={{ width: "60%", display: "inline-block" }}
                              type="text"
                              name="caloriesLimit"
                              className="form-control"
                              id="caloriesLimit"
                              onClick={(e) => e.target.select()}
                              value={formvalues.caloriesLimit}
                              onChange={(e) => {
                                handleFormValues(e);
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <span> Daily: </span>
                            <input
                              style={{ width: "60%", display: "inline-block" }}
                              type="text"
                              name="caloriesDaily"
                              className="form-control"
                              id="caloriesDaily"
                              onClick={(e) => e.target.select()}
                              value={formvalues.caloriesDaily}
                              onChange={(e) => {
                                if (Number(formvalues.caloriesLimit) === 0) {
                                  handleFormValues(e);
                                  return;
                                }
                                if (
                                  Number(e.target.value) >=
                                  Number(formvalues.caloriesLimit)
                                ) {
                                  setFormValues((prv) => ({
                                    ...prv,
                                    caloriesDaily: Number(e.target.value),
                                  }));
                                  return;
                                }
                                handleFormValues(e);
                              }}
                            />
                            <br />
                            {Number(formvalues.caloriesLimit) ===
                            0 ? null : Number(formvalues.caloriesDaily) <=
                              Number(formvalues.caloriesLimit) ? null : (
                              <small id="caloriesDaily" className="text-danger">
                                {error}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-5 col-12 ps-sm-2 px-0 form-check   d-flex row justify-space-between">
                          <div className="fw-bold fs-4 text-black mb-3">
                            Time (seconds)
                          </div>
                          <div className="mb-3">
                            <span> Limit: </span>
                            <input
                              style={{ width: "60%", display: "inline-block" }}
                              type="text"
                              name="timeLimit"
                              className="form-control"
                              id="timeLimit"
                              onClick={(e) => e.target.select()}
                              value={
                                isNaN(formvalues.timeLimit)
                                  ? 0
                                  : Math.floor(formvalues.timeLimit)
                              }
                              onChange={handleFormValues}
                            />
                            {/* <span> sec </span> */}
                          </div>
                          <div className="mb-3">
                            <span> Daily: </span>
                            <input
                              style={{ width: "60%", display: "inline-block" }}
                              type="text"
                              name="timeDaily"
                              className="form-control"
                              id="timeDaily"
                              onClick={(e) => e.target.select()}
                              value={formvalues.timeDaily}
                              // value={
                              //   Number(formvalues.timeDaily) <=
                              //   Number(formvalues.timeLimit)
                              //     ? Math.floor(formvalues.timeDaily)
                              //     : 0
                              // }
                              onChange={(e) => {
                                if (Number(formvalues.timeLimit) === 0) {
                                  handleFormValues(e);
                                  return;
                                }
                                if (
                                  Number(e.target.value) >=
                                  Number(formvalues.timeLimit)
                                ) {
                                  setFormValues((prv) => ({
                                    ...prv,
                                    timeDaily: Number(e.target.value),
                                  }));
                                  return;
                                }
                                handleFormValues(e);
                              }}
                              // onChange={handleFormValues}
                            />
                            <br />
                            {Number(formvalues.timeLimit) === 0 ? null : Number(
                                formvalues.timeDaily
                              ) <= Number(formvalues.timeLimit) ? null : (
                              <small id="timeDaily" className="text-danger">
                                {error}
                              </small>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-5 col-12 form-check px-0 pe-sm-2">
                          <div className="fw-bold fs-4 text-black mb-3 ">
                            Distance (meters)
                          </div>

                          <div className="mb-3">
                            <span> Limit: </span>
                            <input
                              style={{ width: "60%", display: "inline-block" }}
                              type="text"
                              name="distanceLimit"
                              className="form-control"
                              id="distanceLimit"
                              onClick={(e) => e.target.select()}
                              value={formvalues.distanceLimit}
                              onChange={handleFormValues}
                            />
                          </div>
                          <div className="mb-3">
                            <span> Daily: </span>
                            <input
                              style={{ width: "60%", display: "inline-block" }}
                              type="text"
                              name="distanceDaily"
                              className="form-control"
                              id="distanceDaily"
                              onClick={(e) => e.target.select()}
                              value={formvalues.distanceDaily}
                              // value={
                              //   Number(formvalues.distanceDaily) <=
                              //   Number(formvalues.distanceLimit)
                              //     ? formvalues.distanceDaily
                              //     : ""
                              // }

                              onChange={(e) => {
                                if (Number(formvalues.distanceLimit) === 0) {
                                  handleFormValues(e);
                                  return;
                                }
                                if (
                                  Number(e.target.value) >=
                                  Number(formvalues.distanceLimit)
                                ) {
                                  setFormValues((prv) => ({
                                    ...prv,
                                    distanceDaily: Number(e.target.value),
                                  }));
                                  return;
                                }
                                handleFormValues(e);
                              }}
                            />
                            <br />
                            {Number(formvalues.distanceLimit) ===
                            0 ? null : Number(formvalues.distanceDaily) <=
                              Number(formvalues.distanceLimit) ? null : (
                              <small id="distanceDaily" className="text-danger">
                                {error}
                              </small>
                            )}
                          </div>
                        </div>

                        {filterTemplate.sort().join("").includes("3") ? (
                          <div className="col-lg-2 col-md-3 col-sm-5 col-12 form-check px-0 pe-sm-2">
                            <div className="fw-bold fs-4 text-black mb-3 ">
                              Jumps
                            </div>

                            <div className="mb-3">
                              <span> Limit: </span>
                              <input
                                style={{
                                  width: "60%",
                                  display: "inline-block",
                                }}
                                type="text"
                                name="jumpsLimit"
                                className="form-control"
                                id="jumpsLimit"
                                onClick={(e) => e.target.select()}
                                value={formvalues.jumpsLimit}
                                onChange={handleFormValues}
                              />
                            </div>

                            <div className="mb-3">
                              <span> Daily: </span>
                              <input
                                style={{
                                  width: "60%",
                                  display: "inline-block",
                                }}
                                type="text"
                                name="jumpsDaily"
                                className="form-control"
                                id="jumpsDaily"
                                onClick={(e) => e.target.select()}
                                value={formvalues.jumpsDaily}
                                // value={
                                //   Number(formvalues.jumpsDaily) <=
                                //   Number(formvalues.jumpsLimit)
                                //     ? formvalues.jumpsDaily
                                //     : ""
                                // }
                                onChange={(e) => {
                                  if (Number(formvalues.jumpsLimit) === 0) {
                                    handleFormValues(e);
                                    return;
                                  }
                                  if (
                                    Number(e.target.value) >=
                                    Number(formvalues.jumpsLimit)
                                  ) {
                                    setFormValues((prv) => ({
                                      ...prv,
                                      jumpsDaily: Number(e.target.value),
                                    }));
                                    return;
                                  }
                                  handleFormValues(e);
                                }}
                              />
                              <br />
                              {Number(formvalues.jumpsLimit) ===
                              0 ? null : Number(formvalues.jumpsDaily) <=
                                Number(formvalues.jumpsLimit) ? null : (
                                <small id="jumpsDaily" className="text-danger">
                                  {error}
                                </small>
                              )}
                            </div>
                          </div>
                        ) : null}

                        {filterTemplate.sort().join("").includes("5") ? (
                          <div className="col-lg-2 col-md-3 col-sm-5 col-12 form-check px-0 pe-sm-2">
                            <div className="fw-bold fs-4 text-black mb-3 ">
                              Skips
                            </div>

                            <div className="mb-3">
                              <span> Limit: </span>
                              <input
                                style={{
                                  width: "60%",
                                  display: "inline-block",
                                }}
                                type="text"
                                name="skipsLimit"
                                className="form-control"
                                id="skipsLimit"
                                onClick={(e) => e.target.select()}
                                value={formvalues.skipsLimit}
                                onChange={handleFormValues}
                              />
                            </div>
                            <div className="mb-3">
                              <span> Daily: </span>
                              <input
                                style={{
                                  width: "60%",
                                  display: "inline-block",
                                }}
                                type="text"
                                name="skipsDaily"
                                className="form-control"
                                id="skipsDaily"
                                onClick={(e) => e.target.select()}
                                value={formvalues.skipsDaily}
                                // value={
                                //   Number(formvalues.skipsDaily) <=
                                //   Number(formvalues.skipsLimit)
                                //     ? formvalues.skipsDaily
                                //     : 0
                                // }
                                // onChange={handleFormValues}
                                onChange={(e) => {
                                  if (Number(formvalues.skipsLimit) === 0) {
                                    handleFormValues(e);
                                    return;
                                  }
                                  if (
                                    Number(e.target.value) >=
                                    Number(formvalues.skipsLimit)
                                  ) {
                                    setFormValues((prv) => ({
                                      ...prv,
                                      skipsDaily: Number(e.target.value),
                                    }));
                                    return;
                                  }
                                  handleFormValues(e);
                                }}
                              />
                              <br />
                              {Number(formvalues.skipsLimit) ===
                              0 ? null : Number(formvalues.skipsDaily) <=
                                Number(formvalues.skipsLimit) ? null : (
                                <small id="skipsDaily" className="text-danger">
                                  {error}
                                </small>
                              )}
                            </div>
                          </div>
                        ) : null}

                        {filterTemplate.sort().join("").includes("1") ||
                        filterTemplate.sort().join("").includes("5") ||
                        filterTemplate.sort().join("").includes("4") ? (
                          <div className="col-lg-2 col-md-3 col-sm-5 col-12 form-check px-0 pe-sm-2">
                            <div className="fw-bold fs-4 text-black mb-3 ">
                              Steps
                            </div>

                            <div className="mb-3">
                              <span> Limit: </span>
                              <input
                                style={{
                                  width: "60%",
                                  display: "inline-block",
                                }}
                                type="text"
                                name="stepsLimit"
                                className="form-control"
                                id="stepsLimit"
                                onClick={(e) => e.target.select()}
                                value={formvalues.stepsLimit}
                                onChange={handleFormValues}
                              />
                            </div>
                            <div className="mb-3">
                              <span> Daily: </span>
                              <input
                                style={{
                                  width: "60%",
                                  display: "inline-block",
                                }}
                                type="text"
                                name="stepsDaily"
                                className="form-control"
                                id="stepsDaily"
                                onClick={(e) => e.target.select()}
                                // value={
                                //   Number(formvalues.stepsDaily) <=
                                //   Number(formvalues.stepsLimit)
                                //     ? formvalues.stepsDaily
                                //     : 0
                                // }

                                value={formvalues.stepsDaily}
                                onChange={(e) => {
                                  if (Number(formvalues.stepsLimit) === 0) {
                                    handleFormValues(e);
                                    return;
                                  }
                                  if (
                                    Number(e.target.value) >=
                                    Number(formvalues.stepsLimit)
                                  ) {
                                    setFormValues((prv) => ({
                                      ...prv,
                                      stepsDaily: Number(e.target.value),
                                    }));
                                    return;
                                  }
                                  handleFormValues(e);
                                }}
                                // onChange={handleFormValues}
                              />
                              <br />
                              {Number(formvalues.stepsLimit) ===
                              0 ? null : Number(formvalues.stepsDaily) <=
                                Number(formvalues.stepsLimit) ? null : (
                                <small id="stepsDaily" className="text-danger">
                                  {error}
                                </small>
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div className="row mx-0">
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12 form-check px-0">
                          <div className="fw-bold fs-4 text-black mb-3 ">
                            Distance (KMs)
                          </div>
                          <div className="mb-3">
                            <input
                              className="form-check-input p-2 mx-2 ms-0"
                              type="checkbox"
                              name="maximum"
                              id="distance"
                              value="1"
                              onChange={handleFormValues}
                              checked={formvalues.maximum == 1 ? true : false}
                            />
                            Set Maximum
                          </div>
                          {!formvalues.maximum ? (
                            <div className="mb-3">
                              <input
                                type="number"
                                className="form-control"
                                name="distance"
                                id="distance"
                                placeholder="Enter Distance (KMS)"
                                value={formvalues.distance}
                                onChange={handleFormValues}
                              />
                            </div>
                          ) : null}

                          {/* )} */}
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade">
                      <div className="row mx-0">
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12 form-check px-0">
                          <div className="fw-bold fs-4 text-black mb-3 ">
                            Distance (KMs)
                          </div>
                          <div className="mb-3">
                            <input
                              className="form-check-input p-2 mx-2 ms-0"
                              type="checkbox"
                              value="1"
                              id="flexCheckDefault"
                              checked=""
                              onChange={handleFormValues}
                            />
                            Set Maximum
                          </div>
                          <div className="mb-3">
                            <input
                              type="text"
                              className="form-control "
                              id="enterclories"
                              placeholder="Enter Distance (KMS)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mx-0 pb-5 mb-5 ">
                <div className="col-12 px-0 fs-3 fw-bold text-black mb-3">
                  Event Rewards
                </div>
                <div className="col-md-6 col-sm-8 form-check mb-3">
                  <input
                    className="form-check-input p-2"
                    type="checkbox"
                    id="isMedal"
                    value="isMedal"
                    checked={isChecked}
                    onChange={handleOnChange}
                  />
                  <label className="form-check-label" htmlFor="isMedal">
                    Provide Medal
                  </label>
                </div>
                <div className="col-12 mb-3 px-0 row mx-0">
                  <div className="col-md-6 col-sm-8 px-0">
                    <label htmlFor="points" className="form-label">
                      Event Fitness Points
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="points"
                      id="points"
                      placeholder="Enter Fitness Points"
                      aria-label=""
                      value={formvalues.points}
                      onChange={handleFormValues}
                    />
                  </div>
                  {formErrors?.points ? (
                    <div className="text-danger pt-1">{formErrors?.points}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-8 col-12 px-0">
                  <label htmlFor="rewardDesc" className="form-label">
                    Description
                  </label>
                  <textarea
                    name="rewardDesc"
                    className="form-control"
                    placeholder="Enter Event Description"
                    id="rewardDesc"
                    rows="6"
                    value={formvalues.rewardDesc}
                    onChange={handleFormValues}
                  ></textarea>
                </div>
                {formErrors?.rewardDesc ? (
                  <div className="text-danger pt-1">
                    {formErrors?.rewardDesc}
                  </div>
                ) : (
                  ""
                )}
              </div>

              {/* {(edit == true && location.state.data.admin) || edit == false ? (
                <div className="row mx-0  ">
                  <div className="col-12 px-0 fs-3 fw-bold text-black mb-3">
                    Assign Event Admin
                  </div>
                  <div className="col-12 px-0">
                    <div className="col-md-6 col-sm-8 px-0">
                      <div className="input-group">
                        <span
                          className="input-group-text border-end-0 rounded-0"
                          id="basic-addon1"
                        >
                          <i className="bi bi-search"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control border-start-0 shadow-none rounded-0"
                          placeholder="Search by registered username"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          onClick={(e) => e.target.select()}
                          value={search}
                          onChange={(e) => handleuserEvent(e)}
                          onKeyPress={(event) => {
                            if (event.charCode == 13) {
                              handleuserEvent(event);
                            }
                          }}
                        />
                      </div>
                      <div className="searchOption">
                        <select
                          className="form-select rounded-0"
                          multiple={true}
                          onChange={(e) => handleSelectChange(e)}
                          defaultValue={[defaultV]}
                        >
                          {eventData?.map((el, i) => {
                            return (
                              <option
                                value={el._id}
                                key={i}
                                onClick={() => handleEventId(el)}
                                selected={
                                  edit
                                    ? location.state.data.admin?._id == el._id
                                    : false
                                }
                              >
                                {`${el.firstName} ${el.lastName}`}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  {formErrors?.admin ? (
                    <div className="text-danger pt-1">{formErrors?.admin}</div>
                  ) : (
                    ""
                  )}
                </div>
              ) : null} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagePublicAddEvent;
